import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import TextContent from '../component/TextContent/TextContent'
import OccupiedStagging from '../component/OccupiedStagging/OccupiedStagging'
import { Helmet } from 'react-helmet'

function Builders() {
    return (
        <>
         <Helmet>
          <title> 
          Professional  Home Staging Services For Builders
          </title>
          <meta 
          name='description' 
          content="Elevate new builds with MES Décors. Our expert home  staging services in Canada transform empty spaces into attractive homes, boosting buyer interest and accelerating sales." 
          />
          
        </Helmet>
        
            <HeroBanner 
                subHeading=""
                Heading="PROFESSIONAL  STAGING FOR BUILDERS"
                nextLineSpanHeading=""
                Paragraph="Elevate your new build and showing homes with our professional staging services. At MES Décors, we transform empty builds into attractive, livable spaces that captivate potential buyers."
                bgImg="/images/creatives/builders-hero-bg.jpg"
                btn={false}
                sideLinks={false}
            />
    
            <TextContent
                subHeading=""
                heading= "DOES SHOW HOMES STAGING WORK?"
                content={`Certainly! Presenting new builds or show homes can significantly influence the buying decision process of the investors or first-time home buyers. By furnishing and decorating the property, we help buyers envision how they can utilize and enjoy the space. Staged homes often increase the desirability for clients to purchase the new build and may lead them to spend more on furniture, accessories, or showcased features. This positive impact can contribute to the builder's revenue and expedite the sale of the project. At MES Décors, our staging expertise ensures that your new builds stand out, leaving a lasting impression on potential buyers.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={false}
                img="/images/creatives/builders-text-1.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />

            <OccupiedStagging
              heading="STAGING FOR BUILDERS AND DEVELOPERS"
              content="Making your new constructions market-ready is straightforward with our efficient process. Here's how MES Décors makes it happen:"
              card1={{
                title:"Briefing and design process",
                content:"We begin by conducting a thorough briefing to comprehend the project, target market, and the buyer's persona. This allows us to develop a customized design plan that showcases the new build's best features. At this stage, the builders supply the drawings and floor plans which will then be transformed into 2D and 3D drawings."
              }}
              card2={{
                title:"Presentation of the Staging proposal",
                content:"After the proposal has been prepared, MES Décors will present the furniture and accessories intended go into the new build or show home. We will then agree on a plan to stage the units in the best possible way to attract the targeted clientele and ensure a quick sale."
              }}
              card3={{
                title:"Showcasing",
                content:"We bring in stylish furniture and decor to create a warm, inviting atmosphere that helps buyers imagine themselves living in the new space, ensuring the property looks its absolute best."
              }}
            />

            {/* <TextContent
                subHeading=""
                heading= "The Art of Builder Staging"
                content={`Staging is essential for showcasing the potential of new constructions. At MES Décors, we specialize in the art of builder staging, transforming empty houses into appealing homes. Our expert team carefully selects and arranges furniture, artwork, and accessories to highlight each room's function and beauty. By creating a lived-in feel, we help buyers connect emotionally with the space, making it easier for them to envision their future there. Let us enhance your new builds and turn them into buyer magnets.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/builders-text-2.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            /> */}
           
          <GetInTouch
            heading='Let’s Start Staging Today!'
          />
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default Builders