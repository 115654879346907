import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import TextContent from '../component/TextContent/TextContent'
import OccupiedStagging from '../component/OccupiedStagging/OccupiedStagging'
import { Helmet } from 'react-helmet'

function Realators() {
    return (
        <>
         <Helmet>
          <title> 
          Professional Home Staging Services For Realtors
          </title>
          <meta 
          name='description' 
          content="Enhance property listings with MES Décors’ expert home staging & Interior Design service in Canada Transform spaces to captivate buyers and achieve faster, higher sales with our stylish, strategic approach." 
          />
          
        </Helmet>
        
            <HeroBanner 
                subHeading=""
                Heading="PROFESSIONAL HOME STAGING FOR REALTORS"
                nextLineSpanHeading=""
                Paragraph="At MES Décors, we empathize with realtors and strive to be their ideal partners."
                bgImg="/images/creatives/realator-hero-bg.jpg"
                btn={false}
                sideLinks={false}
            />
    
            <TextContent
                subHeading=""
                heading= "DOES STAGING WORK FOR REALTORS?"
                content={`Absolutely! Staging significantly enhances the marketability of a property. By decluttering, organizing, and stylishly furnishing spaces, staging allows buyers to envision themselves living there. Studies indicate that staged properties sell more quickly and often at higher prices. At MES Décors, our staging expertise ensures their listings stand out, leaving a lasting impression on potential buyers. \n Our comprehensive services are designed to add value to their clients and simplify their lives. We share a common goal of enhancing the appeal of their listings through professional staging services. Our aim is to transform properties, attract more potential buyers, and facilitate faster sales.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={false}
                img="/images/creatives/realator-text-1.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />

            <OccupiedStagging
              heading="PROCESS OF STAGING FOR REALTORS"
              content="Transforming your listings is straightforward with our efficient process. Here's how MES Décors makes it happen:"
              card1={{
                title:"Consultation",
                content:"We start by thoroughly understanding the property, including its current situation and the Realtor's vision and plan to sell it, uncovering areas leaking equity and providing solutions to prevent them. A comprehensive consultation is recommended to grasp the property's distinctive features and the necessary work to make it stand out in the market. This process aids in developing a customized staging plan. \n We believe in simplicity and flexibility when working with Realtors. We are available to strategically stage based on the goal they would like to achieve."
              }}
              card2={{
                title:"The Work",
                content:"Our expertise in the real estate market allows us to offer a variety of services to Realtors, including decluttering, organizing, moving, professional photography, project management and access to a wide range of trusted contractors and suppliers. These services are provided upon request. Realtors collaborate with their clients to implement the recommendations as quickly as possible to meet the agreed staging date."
              }}
              card3={{
                title:"Showcasing",
                content:"On the staging date, our team will arrive to present the property according to the agreed look and feel. Whether we are using the homeowner's furniture and accessories or providing our own, our team will accentuate the property's main features and create an inviting environment, which will help realtors sell faster and achieve the highest possible price."
              }}
            />

            <TextContent
                subHeading=""
                heading= "THE ART OF REALTOR STAGING"
                content={`Staging is a powerful tool for making properties more attractive to buyers. At MES Décors, we excel in the art of realtor staging, transforming spaces to maximize their appeal. Our expert team selects and arranges furniture, artwork, and accessories to enhance each room's potential and charm. By creating a warm and inviting atmosphere, we help buyers emotionally connect with the property, increasing the likelihood of a quick and profitable sale. Trust us to make your listings shine and captivate buyers.\n In vacant homes, our staging process is similar to occupied home staging. We help Realtors identify necessary upgrades through our consultation process. Once recommendations are made, we complete the showcasing using furniture and accessories that we provide, creating the buyer's dream home.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/realator-text-2.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />
           
          <GetInTouch
            heading='Let’s Start Staging Today!'
          />
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default Realators