import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import TextContent from '../component/TextContent/TextContent'
import DecoratingInnerMidContent from '../component/DecoratingInnerMidContent/DecoratingInnerMidContent'
import { Helmet } from 'react-helmet'

function OrganizingDecorating() {
    return (
        <>
         <Helmet>
          <title> 
          Expert Decluttering & Organizing Services
          </title>
          <meta 
          name='description' 
          content="Transform your home with MES Décors. Our expert decluttering and organizing services in Canada create calm, functional spaces that enhance well-being and productivity." 
          />
          
        </Helmet>
        
            <HeroBanner 
                subHeading=""
                Heading="DECLUTTERING & ORGANIZING"
                nextLineSpanHeading=""
                Paragraph="Simplify your space, find peace of mind, and experience order with MES Décors."
                bgImg="/images/creatives/decorating-organizing-hero-bg.jpg"
                btn={false}
                sideLinks={false}
            />
    
            <TextContent
                subHeading=""
                heading= "HOW DOES DECLUTTERING & ORGANIZING MAKE A DIFFERENCE?"
                content={`Decluttering and organizing isn't just about tidying up; it's about transforming your home into a sanctuary of calm and clarity. By eliminating excess and implementing efficient storage solutions, we create space for you to breathe, think, and thrive. Experience the transformative power of decluttering and organizing with MES Décors and discover how a clutter-free environment can enhance your well-being and productivity.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/decorating-organizing-text-1.png"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />

            <DecoratingInnerMidContent
                heading="A BROAD SET OF DESIGN"
                content="Explore the multifaceted world of interior design through the lens of decluttering and organizing with MES Décors. From optimizing closet space to creating functional workspaces, our approach ensures that every corner of your home is designed to support your lifestyle and promote a sense of balance and serenity."
                img1="/images/creatives/decorating-organizing-text-2.jpg"
                img2="/images/creatives/decorating-organizing-text-3.jpg"
            />

            <TextContent
                subHeading=""
                heading= "THE ART OF DECLUTTERING & ORGANIZING"
                content={`Experience the artistry of decluttering and organizing with MES Décors. Our skilled professionals combine practical expertise with creative flair to transform your space into a haven of order and tranquility. Let us guide you on a journey towards a clutter-free lifestyle, where simplicity reigns supreme and every item has its place.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/decorating-organizing-text-4.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />
           
          <GetInTouch
            heading='Let’s Start Staging Today!'
          />
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default OrganizingDecorating