import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import Contactform from '../component/Contact Form/Contactform'
import DecoratingInteriorDesign from '../component/DecoratingInteriorDesign/DecoratingInteriorDesign'
import { Helmet } from 'react-helmet'

function Decorating() {
    return (
        <>
                      
            <Helmet>
                <title> 
                Expert Decorating Services | Interior Design Service Consultation Canada
                </title>
                <meta 
                name='description' 
                content="Discover MES Décors' expert decorating services for homes and businesses. Enhance your space with personalized, stylish, and creative interior design solutions and Consultation in Canada" 
                />

                </Helmet>
        
            <HeroBanner 
                subHeading=""
                Heading="Decorating"
                nextLineSpanHeading=""
                Paragraph="Transform your space into a reflection of your style and personality with our expert decorating services. Let us bring your vision to life, one beautiful detail at a time."
                bgImg="/images/creatives/decorating-hero-bg.jpg"
                btn={false}
                sideLinks={false}
            />
    
          <DecoratingInteriorDesign
            Heading="A BROAD SET OF INTERIOR DESIGN"
            content="Discover the art of interior design with MES Décors. Our comprehensive approach encompasses everything from color palettes to furniture arrangement, ensuring every aspect of your space harmonizes seamlessly. With a keen eye for detail and a passion for creativity, we turn ordinary rooms into extraordinary living spaces. From contemporary chic to timeless elegance, our diverse range of interior design services caters to every taste and style. Let us unleash the full potential of your home and create a space that truly reflects who you are."
            

          />
           
          <Contactform/>
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default Decorating