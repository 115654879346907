import React,{useState,useEffect,useRef} from 'react'
import "./QuoteForm.css"
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function QuoteForm() {
    const [firstName,setFirstName]= useState()
    const [lastname,setLastName]= useState()
    const [email,setEmail]= useState()
    const [phone,setPhone]= useState()
    const [streetAddress , setStreetAddress] = useState()
    const [addressLine2,setAddressLine2]= useState("")
    const [city,setCity]= useState()
    const [addState,setAddState]= useState("Ontario")
    const [postalCode,setpostalCode]= useState()
    const [occupation,setOccupation]= useState("")
    const [propertySize,setPropertySize]= useState()
    const [propertyType,setPropertyType]= useState()
    const [propertyStatus,setPropertyStatus]= useState("")
    const [listPrice,setListPrice]= useState("")
    const [additionalText,setAdditionalText]= useState()
    
    const [cv,setCv] = useState(null)
    const [uploading,setUploading] = useState(false)

    const [value, onChange] = useState(new Date());
    
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()


    const numericInputsRef = useRef([]);

    useEffect(() => {
        const handleWheel = (e) => {
          // Prevent the default behavior of the wheel event
          e.preventDefault();
        };
      
        // Ensure numericInputsRef is defined and not null
        // if (numericInputsRef && numericInputsRef.current) {
        //   const numericInputs = numericInputsRef.current;
      
        //   // Attach the wheel event listener with { passive: false }
        //   numericInputs.forEach((numericInput) => {
        //     numericInput.addEventListener('wheel', handleWheel, { passive: false });
        //   });
      
        //   // Cleanup the event listeners when the component unmounts
        //   return () => {
        //     numericInputs.forEach((numericInput) => {
        //       // Check if the element is still in the DOM before removing the event listener
        //       if (numericInput && numericInput.removeEventListener) {
        //         numericInput.removeEventListener('wheel', handleWheel);
        //       }
        //     });
        //   };
        // }
      
        
        return () => {};
      }, [numericInputsRef]);
      
      
  

      const cvFileHandler = async (e) => {
        const files = e.target.files;
        if (files.length > 5) {
            alert("You can upload a maximum of 5 images.");
            e.target.value = "";
            return;
        }
    
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("images", files[i]);
        }
    
        setUploading(true);
        try {
            const config = { Headers: { "Content-Type": "multipart/form-data" } };
            const { data } = await axios.post("/api/upload/assessment", formData, config);
            setCv(data.fileNames);
            setUploading(false);
        } catch (error) {
            console.error(error);
            setCv(null);
            e.target.value = "";
            alert("An error occurred while uploading your files. Please ensure they are images and each is less than 10MB.");
            setUploading(false);
        }
    };
    

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        if(cv === null)
        {
            setLoading(false)
            alert("Please upload a image file")
            return
        }
        axios
          .post("/send-assesment", {
            email: email,
            formType: "Get a Qoute",
            assementData:{
                firstName:firstName,
                lastName:lastname,
                email:email,
                phone:phone,
                streetAddress:streetAddress,
                addressLine2:addressLine2,
                city:city,
                state:addState,
                postalCode:postalCode,
                occupation:occupation,
                propertySize:propertySize,
                propertyType:propertyType,
                propertyStatus:propertyStatus,
                listPrice:listPrice,
                additionalText:additionalText,
                propertyPhotos:cv,
                date:value
            }
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Quote form Submited Successfully!");
              setLoading(false);
              setEmail("");
            //   window.open("/thank-you", '_blank');
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Failed to send Quoute form, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setDob(prevDate => ({
    //       ...prevDate,
    //       [name]: value
    //     }));
    //   };

      

      const handlePhoneChange = (e) => {
        
        const numericValue = e.target.value.replace(/\D/g, ''); 
        const maxLength = 20; 
    
        
        if (numericValue.length <= maxLength) {
          setPhone(numericValue);
        }
      };
  return (
    <div className='assessment-form-container standard-padding-space'>
        <div className='container'>
            <h3 className='body-heading  pb-4'>REQUEST A QUOTE</h3>

            <form className='form-container py-3' onSubmit={formSubmitHandler}>
                <h5 className='sub-heading my-4 mb-3' >YOUR CONTACT INFORMATION</h5>
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6'>
                        
                        <input
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            maxLength="36"
                            required
                            type="text"
                            placeholder='Your First Name'
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6'>
                        
                        <input
                            value={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                            maxLength="36"
                            required
                            type="text"
                            placeholder='Your Last Name'
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6'>
                       
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            maxLength="100"
                            required
                            type='email' 
                            placeholder='Email Address' 
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6'>
                        
                        <input
                            value={phone}
                            onChange={handlePhoneChange}
                            min="15" max="20"
                            required
                            type='tel' 
                            placeholder='Your Phone Number'

                        />
                    </div>
                </div>    

                <h5 className='sub-heading my-4 mb-3' >Property Address</h5>
                <div className='row'>
                   
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6'>
                       
                        <input
                            value={streetAddress}
                            onChange={(e) => setStreetAddress(e.target.value)}
                            maxLength="50"
                            required
                            type="text"
                            placeholder='Street Address'
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6'>
                        
                        <input
                            value={addressLine2}
                            onChange={(e) => setAddressLine2(e.target.value)}
                            maxLength="50"
                            type="text"
                            placeholder='Address line 2'
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6'>
                        
                        <input
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            maxLength="50"
                            required
                            type="text"
                            placeholder='City'
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-6'>
                        
                        
                        <select 
                            value={addState} 
                            onChange={(e)=>{setAddState(e.target.value)}}
                            className='inp-label py-2'
                        >
                            <option value="Ontario">Ontario</option>
                            <option value="Quebec">Quebec</option>
                            <option value="British Columbia">British Columbia</option>
                            <option value="Alberta">Alberta</option>
                            <option value="Manitoba">Manitoba</option>
                            <option value="Saskatchewan">Saskatchewan</option>
                            <option value="Nova Scotia">Nova Scotia</option>
                            <option value="New Brunswick">New Brunswick</option>
                            <option value="Prince Edward Island">Prince Edward Island</option>
                            <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                            <option value="Northwest Territories">Northwest Territories</option>
                            <option value="Yukon">Yukon</option>
                            <option value="Nunavut">Nunavut</option>

                            
                        </select>
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                        
                        <input
                            value={postalCode}
                            onChange={(e) => setpostalCode(e.target.value)}
                            maxLength="50"
                            required
                            type="text"
                            placeholder='Postal Code'
                        />
                    </div>
                </div>    

                <h5 className='sub-heading my-4 mb-3' >Tell us a little bit about yourself</h5>
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                        <select 
                            value={occupation} 
                            onChange={(e)=>{setOccupation(e.target.value)}}
                            className='inp-label py-2'
                            required
                        >
                            <option value="" disabled selected hidden>Select From Dropdown</option>
                            <option value="Home Owner">Home Owner</option>
                            <option value="Builder ">Builder </option>
                            <option value="Realtor ">Realtor</option>
                        </select>
                    </div>
                </div>  

                <h5 className='sub-heading my-4 mb-3' >Allow us to help you</h5>
                <div className='row'>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                        
                        <input
                            value={propertySize}
                            onChange={(e) => setPropertySize(e.target.value)}
                           
                            required
                            type="text"
                            placeholder="Property Size"
                            className='numeric-input'
                            
                        />
                    </div>
                    <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                        
                        <input
                            value={propertyType}
                            onChange={(e) => setPropertyType(e.target.value)}
                           
                            required
                            type="text"
                            placeholder="Property Type"
                            className='numeric-input'
                            
                        />
                    </div>
                </div>    
                <div className='row gy-4 align-items-end'>
                    <div className='col-lg-6'>
                        <div className='form-field d-flex flex-column justify-content-between col-12'>
                            <select 
                                value={propertyStatus } 
                                onChange={(e)=>{setPropertyStatus(e.target.value)}}
                                className='inp-label py-2'
                                required
                            >
                                <option value="" disabled selected hidden>Select From Dropdown</option>
                                <option value="Vacant">Vacant</option>
                                <option value="Occupied">Occupied</option>
                                <option value="under renovation">Under Renovation</option>
                            </select>
                        </div>
                        <div className='form-field d-flex flex-column justify-content-between col-12'>
                            <input
                                value={listPrice } 
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if(newValue >= 0) {
                                        setListPrice(newValue);
                                    }
                                }}           
                                type="number"
                                placeholder="Your Estimated List Price"
                                className='numeric-input'
                                min={0}
                            />
                           
                        </div>
                        <div className='form-field d-flex flex-column justify-content-between col-12'>
                            <textarea 
                                value={additionalText } 
                                onChange={(e)=>{setAdditionalText(e.target.value)}}
                                className='inp-label py-2'
                                rows={6}
                                placeholder="Do you have any more information you'd like to share with us?"
                            >
                            </textarea>
                        </div>
                        <div className='form-field d-flex flex-column justify-content-between col-lg-12'>
                            <div className="custom-file-input">
                                <p style={{fontSize:"12px",marginBottom:"8px"}}>Note : upload limit (maximum 5 photos)</p>
                                <input 
                                    type="file" 
                                    id="fileInput" 
                                    style={{ display: 'none' }} 
                                    onChange={cvFileHandler} 
                                    multiple 
                                />
                                <label htmlFor="fileInput" className="file-input-label d-flex justify-content-evenly">
                                    {cv ? `${cv.length} Files uploaded` : 'Upload photos of the property'}
                                    <img src='/images/icons/file-down.svg' alt='file download icon' />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 px-3'>
                        <h5 className='sub-heading my-4 mb-3' >EXPECTED STAGING DATE</h5>
                        <Calendar onChange={onChange} value={value} className={"w-100 date-inp"}/>
                        <div className='col-lg-12 mt-4'>
                            <button className='genral-btn-light submit-btn w-100 d-flex justify-content-center align-items-center' type='submit'>
                                Submit Now
                                {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "grey",marginLeft:"10px" }}
                                    />
                                ) : (
                                success ? (
                                    <div>
                                    {setLoading(false)}
                                    {alert(success)}
                                    {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                    <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                    </div>
                                    )
                                )
                                )}
                            </button>
                        </div>
                    </div>
                   
                   
                </div>

            </form>
        </div>
    </div>
  )
}

export default QuoteForm