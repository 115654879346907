import './App.css';
import "./bootstrap.min.css"
import About from './screens/About';
import Home from './screens/Home';
import { Routes, Route, Navigate } from 'react-router-dom';

import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";

import NotFound from "./screens/NotFound"


import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./component/LocationPages_/LocationPages"
import Stagging from './screens/Stagging';
import HomeOwner from './screens/HomeOwner';
import Realators from './screens/Realators';
import Builders from './screens/Builders';
import Decorating from './screens/Decorating';
import HomeOwnerDecorating from './screens/HomeOwnerDecorating';
import ShortRentalDecorating from './screens/ShortRentalDecorating';
import OrganizingDecorating from './screens/OrganizingDecorating';
import Portfolio from './screens/Portfolio';
import News from './screens/News';
import BlogInner from './screens/BlogInner';
import Contact from './screens/Contact';
import GetAQoute from './screens/GetAQoute';
import Commercial from './screens/Commercial';


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" replace={true}/>} />
        <Route path="/about" element={<About />} />
        <Route path="/staging" element={<Stagging />} />
        <Route path="/staging-services-in-canada" element={<Navigate to="/staging" replace={true}/>} />
        <Route path="/staging-home-owner" element={<HomeOwner />} />
        <Route path="/staging/staging-home-owner-services-in-canada" element={<Navigate to="/staging-home-owner" replace={true}/>} />
        <Route path="/staging/home-owner" element={<Navigate to="/staging-home-owner" replace={true}/>} />
        <Route path="/staging-realtors" element={<Realators />} />
        <Route path="/staging-realtors-services-in-canada" element={<Navigate to="/staging-realtors" replace={true}/>} />
        <Route path="/staging/realtors" element={<Navigate to="/staging-realtors" replace={true}/>} />
        <Route path="/staging-builders" element={<Builders />} />
        <Route path="/staging-builders-services-in-canada" element={<Navigate to="/staging-builders" replace={true}/>} />
        <Route path="/staging/builders" element={<Navigate to="/staging-builders" replace={true}/>} />
        <Route path="/decorating" element={<Decorating />} />
        <Route path="/decorating-services-in-canada" element={<Navigate to="/decorating" replace={true}/>} />
        <Route path="/decorating-home-owner" element={<HomeOwnerDecorating />} />
        <Route path="/decoratimg/residential" element={<Navigate to="/decorating-home-owner" replace={true}/>} />
        <Route path="/decorating-short-term-rental" element={<ShortRentalDecorating />} />
        <Route path="/decorating-organizing" element={<OrganizingDecorating />} />
        <Route path="/decorating-organizing-services-in-canada" element={<Navigate to="/decorating-organizing" replace={true}/>} />
        <Route path="/decorating/organizing" element={<Navigate to="/decorating-organizing" replace={true}/>} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blog" element={<News />} />
        <Route path="/artical/:id" element={<BlogInner />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/get-a-quote" element={<GetAQoute />} />
        <Route path="/commercial" element={<Commercial />} />
        <Route path="/decorating/commercial" element={<Navigate to="/commercial" replace={true}/>} />
        
        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />

        
        <Route path="*" element={<NotFound />} />

        
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>
      </Routes>
    </div>
  );
}

export default App;


