import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import TextContent from '../component/TextContent/TextContent'
import OccupiedStagging from '../component/OccupiedStagging/OccupiedStagging'
import { Helmet } from 'react-helmet'

function Commercial() {
    return (
        <>
          <Helmet>
            <title> 
            Professional Commercial, Residential Interior Decorating Services Canada 
            </title>
            <meta 
            name='description' 
            content="Transform your business environment with MES Décors' expert commercial residential interior decorating services in Canada. Boost productivity, customer satisfaction, and revenue." 
            />

            </Helmet>
        
            <HeroBanner 
                subHeading=""
                Heading="PROFESSIONAL COMMERCIAL DECORATING SERVICES"
                nextLineSpanHeading=""
                Paragraph="Transform your business environment with MES Décors' professional Commercial Decorating Services. Our expert team will help you create a new space or enhance your existing one boosting your employees’ productivity and your customers’ satisfaction, hence, increasing revenue."
                bgImg="/images/creatives/commercial-hero-bg.jpg"
                btn={false}
                sideLinks={false}
            />
    
            <TextContent
                subHeading=""
                heading= "DOES COMMERCIAL DECORATING REALLY WORK?"
                content={`We at MES Décors work with business owners to understand their businesses and the products and services that they are selling, as well as their targeted market and audience. Whether it is a new business concept or an existing one that needs upgrading, we are happy to partner with the business owners to translate their vision into their space or redecorate their existing’s to an up-to-date one.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={false}
                img="/images/creatives/commercial-text-1.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />

            <OccupiedStagging
              heading="EXISTING COMMERCIAL DECORATING"
              content="Enhance the appeal of your commercial space with our expert Decorating Services."
              card1={{
                title:"Consultation",
                content:"Our process begins with a comprehensive consultation, where we assess the current space. We discuss the business needs, target audience, and desired outcomes. This helps us create a tailored decorating plan that maximizes your space potential within your budget."
              }}
              card2={{
                title:"The work",
                content:"Once the plan is in place, our professional team gets to work. We carefully select and virtually place furniture, decor, and accessories that enhance the functionality and aesthetic appeal of your space. The plan will be presented to the client for their approval aiming to create an inviting environment that resonates with employees and clients boosting the business's look and feel."
              }}
              card3={{
                title:"Showcasing",
                content:"After approving the plan, we showcase the transformed space, highlighting its best features. Using the decors and pieces of furniture that have been previously selected based on the plan. Our team ensures everything is perfectly in place, ready for clients and employees to experience. We guide maintaining the staged look and help make a lasting impression that drives business success with minimal interruption. "
              }}
            />

            <OccupiedStagging
              heading="NEW COMMERCIAL DESIGN"
              content="Transform your empty commercial space into a vibrant, appealing space with our specialized Design Services."
              card1={{
                title:"Briefing",
                content:"Our commercial design process begins with a debrief, where we listen to the owner’s vision and goal that they would like to achieve, as well as examine the space's layout and enquire about the building drawing for measurements. We work to understand the brand’s target market and vision, allowing us to develop a customized preliminary plan that highlights the space’s strengths and appeals to potential clients."
              }}
              card2={{
                title:"The work",
                content:"With a clear plan in place, our team brings the vacant space to life. We carefully select and arrange high-quality furniture, decor, and accessories to create a functional and attractive environment. Our focus is on showcasing the space's full potential, making it enjoyable for the employees and clients. Once the business owner approves the proposal, we move to the execution. "
              }}
              card3={{
                title:"Showcasing",
                content:"It is showing time, our team will ensure the space is presented based on the plan. We will meticulously arrange every element to create a polished and inviting look. We also provide tips on how to maintain the appearance, ensuring your space continues to impress."
              }}
              lightTheme={true}
            />
            {/* <TextContent
                subHeading=""
                heading= "THE ART OF STAGING"
                content={`Vacant homes can often feel cold and uninviting, making it hard for potential buyers to envision their lives there. At MES Décors, we specialize in the art of vacant staging, transforming empty spaces into warm, welcoming environments.\n Our team carefully selects and arranges furniture, artwork, and accessories to highlight each room's potential and charm. By creating a lived-in feel, we help buyers connect emotionally with the space, increasing the likelihood of a quick and profitable sale. Let us turn your vacant property into a buyer's dream home.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/home-owner-text-2.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            /> */}
           
          <GetInTouch
            heading='Let’s Start Staging Today!'
          />
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default Commercial