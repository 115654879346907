import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'
import HeroBannerDark from '../component/Common Banner Component Dark/HeroBannerDark'
import TeamContent from '../component/TeamContent/TeamContent'
import Contactform from '../component/Contact Form/Contactform'
import ExtendedTextContent from '../component/ExtendedTextContent/ExtendedTextContent'
import ScrollingTextContent from '../component/ScrollingTextContent/ScrollingTextContent'


function About() {
  return (
    <>
     <Helmet>
          <title> 
          Expert Home & Commercial Staging - 15 Years of Excellence 
          </title>
          <meta 
          name='description' 
          content="Transforming spaces for 15 years with expert staging and design. MES Décors boosts property appeal and sales with luxury solutions for homes and businesses." 
          />
          
        </Helmet>
        <HeroBannerDark 
        subHeading="About Us"
        Heading="A Staging and Design Firm"
        nextLineSpanHeading=""
        Paragraph="At MES Décors, we specialize in transforming homes and commercial spaces with our expert staging and design services. With 15 years of experience, we make every property look at its best to attract buyers and boost sales."
        bgImg=""
        btn={false}
      />

      <TeamContent/>

      <Sponsor/>

      <TextContent
         subHeading="Who we are"
         heading= "ABOUT MES Décors"
         content="MES Décors has been operating for over 15 years. We have experience in luxury retail and interior design, providing home and business owners with holistic interior and exterior solutions including decor and cutting-edge AI technologies for a smart, modern, and sustainable lifestyle. Our services cover residential and commercial estates, supporting our clients to elevate their living or working spaces to reflect their vision while impressing their clients with more luxurious and seamless experiences, hence, boosting revenue and moving businesses to the next level."
         btn={true}
         btnText="Book a consult now"
         btnRef='/contact'
         btnRefTel={false}
         shiftRow={true}
         img="/images/creatives/about-text-1.png"
         reviewContent={false}
         btnImg={false}
         fontDark={false}
         />


      {/* <ExtendedTextContent
         subHeading="meh Dey-kor"
         heading= "The story behind MES Décors"
         content={"Our story began in 2006 when my wife and I decided to partner for life, writing a new chapter in our families’ heritages of refined jewellery making and design and steel manufacturing industries. The savoir fair we inherited throughout the past four decades inspired the idea of creating this unique idea.\n In 2007 our journey started in the Middle East in a very distinctive city called Dubai where every element of this place inspired and motivated us to believe that everything is possible especially if the desert turned green."}
         btn={false}
         shiftRow={true}
         btnRefTel={true}
         img="/images/creatives/about-text-2.1.jpg"
         reviewContent={false}
         btnImg={false}
         dropDown={false}
         extContent={"Our story began in 2006 when my wife and I decided to partner for life, writing a new chapter in our families’ heritages of refined jewellery making and design and steel manufacturing industries. The savoir fair we inherited throughout the past four decades inspired the idea of creating this unique idea.\n In 2007 our journey started in the Middle East in a very distinctive city called Dubai where every element of this place inspired and motivated us to believe that everything is possible especially if the desert turned green.\n Mirna Elie Sarji, My Wife, my inspiration, friend and partner who stood by me since day one, we succeeded and failed many times but never stopped believing in each other or learning from what we passed through.\n 10 years in The United Arab Emirates were full of milestones that we achieved alongside our customers who were extended family members for us, inspiring their lifestyle and uplifting their business image to the next level that they dream of. In 2018, we decided to move on and redesign our life to extend our services to the Canadian market and establish MES Décors company named after my wife.\n MES Décors is a specialist in the Spaces, Interior, and Design of commercial and residential providing holistic services to our clients to enhance their lifestyles and bring more equity to the space. From Decors to Furniture and Tech services we are driving to set the benchmark to be the best in the market.\n With our know-how and market expertise, we provide upscaled and tailored service to the client’s needs and ensure their satisfaction building trust and long-time relationships that last for a lifetime. To my wife,\n I gift MES Décors!"}
         /> */}

        <ScrollingTextContent
          subHeading="meh Dey-kor"
          heading= "THE STORY BEHIND "
          nextLineSpanHeading="MES Décors"
          content={"Our story began in 2006 when my wife and I decided to partner for life, writing a new chapter in our families’ heritages of refined jewellery making and design and steel manufacturing industries. The savoir fair we inherited throughout the past four decades inspired the idea of creating this unique idea.\n In 2007 our journey started in the Middle East in a very distinctive city called Dubai where every element of this place inspired and motivated us to believe that everything is possible especially if the desert turned green.\n Mirna Elie Sarji, My Wife, my inspiration, friend and partner who stood by me since day one, we succeeded and failed many times but never stopped believing in each other or learning from what we passed through.\n 10 years in The United Arab Emirates were full of milestones that we achieved alongside our customers who were extended family members for us, inspiring their lifestyle and uplifting their business image to the next level that they dream of. In 2018, we decided to move on and redesign our life to extend our services to the Canadian market and establish MES Décors company named after my wife.\n MES Décors is a specialist in the Spaces, Interior, and Design of commercial and residential providing holistic services to our clients to enhance their lifestyles and bring more equity to the space. From Decors to Furniture and Tech services we are driving to set the benchmark to be the best in the market.\n With our know-how and market expertise, we provide upscaled and tailored service to the client’s needs and ensure their satisfaction building trust and long-time relationships that last for a lifetime. To my wife,\n I gift MES Décors!"}
          btn={false}
          shiftRow={true}
          btnRefTel={true}
          img="/images/creatives/about-text-2.1.jpg"
        />

        <Contactform/>
      


      <Footer
        newsletterSection={true}
      />
    </>
  )
}

export default About