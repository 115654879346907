import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import TextContent from '../component/TextContent/TextContent'
import DecoratingInnerMidContent from '../component/DecoratingInnerMidContent/DecoratingInnerMidContent'
import { Helmet } from 'react-helmet'

function ShortRentalDecorating() {
    return (
        <>
        
          <Helmet>
            <title> 
            Elevate Your Short-Term Rental with Expert Interior Design  Services
            </title>
            <meta 
            name='description' 
            content="Transform your short-term rental into a standout getaway with MES Décors. Enhance guest experience, boost reviews, and increase bookings with our expert interior design services in Canada." 
            />

            </Helmet>
            <HeroBanner 
                subHeading=""
                Heading="Elevate your rental property "
                spanHeading='WITH '
                nextLineSpanHeading="MES Décors"
                Paragraph="Transform your short-term rental into a memorable getaway with our expert design services."
                bgImg="/images/creatives/decorating-rental-hero-bg.jpg"
                btn={false}
                sideLinks={false}
            />
    
            <TextContent
                subHeading=""
                heading= "HOW DO SHORT TERM RENTALS MAKE A DIFFERENCE?"
                content={`Short-term rentals offer a unique opportunity to provide guests with a personalized and comfortable experience. With our thoughtful design choices and attention to detail, we ensure that every guest feels right at home, leading to positive reviews, repeat bookings, and increased revenue for property owners.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/decorating-rental-text-1.png"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />

            <DecoratingInnerMidContent
                heading="A BROAD SET OF DESIGN"
                content="Discover the versatility of short-term rental interior design with MES Décors. From maximizing space to creating a cohesive theme, our comprehensive approach ensures that every aspect of your property is optimized for guest comfort and enjoyment."
                img1="/images/creatives/decorating-rental-text-2.jpg"
                img2="/images/creatives/decorating-rental-text-3.jpg"
            />

            <TextContent
                subHeading=""
                heading= "THE ART OF SHORT-TERM RENTALS DECORATING"
                content={`Know the potential of your short-term rental property with MES Décors. Our creative expertise and dedication to excellence shine through in every design decision, transforming ordinary spaces into extraordinary retreats. Let us help you create a memorable guest experience that keeps them coming back for more.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/decorating-rental-text-4.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />
           
          <GetInTouch
            heading='Let’s Start Staging Today!'
          />
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default ShortRentalDecorating